import React from "react";
import FooterFour from "../components/FooterFour";
import CaseStudyAreaOne from "../components/CaseStudyAreaOne";
import WorkProcessFour from "../components/WorkProcessFour";
import BrandAreaOne from "../components/BrandAreaOne";
import BannerTwo from "../components/BannerTwo";
import NavbarTwo from "../components/NavbarTwo";


const HomeFour = () => {
  return (
    <>


      {/* Navigation Bar Two*/}
      <NavbarTwo />
      {/* Home Slider */}
      <BannerTwo />

      {/* ORU WORK */}
      <CaseStudyAreaOne />

      {/* Technologies Slider */}
      <BrandAreaOne />

      {/* Work Process Four */}
      <WorkProcessFour />

      {/* Footer Four */}
      <FooterFour />
    </>
  );
};

export default HomeFour;
