import React from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const CaseStudyAreaOne = () => {
  return (
    <>
      {/*================== case-study area start ==================*/}
      <div className='case-study-area bg-relative pd-top-60'>
     
        <img class="position-bottom-left top_image_bounce" src="assets/img/icon/4.png" alt="img" />
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>OUR WORK</h6>
            <h2 className='title'>
              <span>Portfolio</span> 
            </h2>
          </div>
          <div className='row'>
          <div className='col-lg-3 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">Min Payroll</Link>
                  </h6>
                  <p className="h6ZSol">
                    .NET + React.js
                  </p>
                  <a className='cat' href='#'>
                  HR & Payroll System
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>
  
            <div className='col-lg-3 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">Ezy Recruit</Link>
                  </h6>
                  <p className="h6ZSol">
                  .NET + React.js
                  </p>
                  <a className='cat' href='#'>
                  Recruitment System
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">Foody Fly</Link>
                  </h6>
                  <p className="h6ZSol">
                    MERN
                  </p>
                  <a className='cat' href='#'>
                  Reservation System
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">Eventee</Link>
                  </h6>
                  <p className="h6ZSol">
                    Laravel + Next.js
                  </p>
                  <a className='cat' href='#'>
                  Conference Portal
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>           
          

            <div className='col-lg-6 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">Max Manager</Link>
                  </h6>
                  <p className="h6ZSol">
                  .NET + React.js
                  </p>
                  <a className='cat' href='#'>
                  POS System
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>           
             <div className='col-lg-6 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">Motion CRM</Link>
                  </h6>
                  <p className="h6ZSol">
                  .NET + React.js
                  </p>
                  <a className='cat' href='#'>
                  CRM System
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">Rozeo</Link>
                  </h6>
                  <p className="h6ZSol">
                    Django + Next.js
                  </p>
                  <a className='cat' href='#'>
                  Online Store
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>



            <div className='col-lg-3 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">Shoosy</Link>
                  </h6>
                  <p className="h6ZSol">
                    MERN
                  </p>
                  <a className='cat' href='#'>
                  Online Store
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">iStore</Link>
                  </h6>
                  <p className="h6ZSol">
                    .NET + Next.js
                  </p>
                  <a className='cat' href='#'>
                  Online Store
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-case-study-inner'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                </div>
                <div className='details'>
                  <h6 >
                    <Link to='/case-study-details' className="ZSollinkWork">MojRug</Link>
                  </h6>
                  <p className="h6ZSol">
                    Laravel + Next.js
                  </p>
                  <a className='cat' href='#'>
                  Online Store
                  </a>
                  <a className='right-arrow' href='#'>
                    <FaAngleDoubleRight />
                  </a>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
      {/* ================== case-study area end ==================*/}
    </>
  );
};

export default CaseStudyAreaOne;
